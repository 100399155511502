/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ActiveAndInactiveBody from '../../asset-team/active-inactive/ActiveAndInactiveBody';

const PartnershipActiveAndInactiveCustomers = () => {
  const firstName = sessionStorage.getItem('firstName');
  const { profile, user } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <PartnershipNavBar currentPage="Active And Inactive Customers" position={firstName} />
        <ActiveAndInactiveBody profile={profile} />
      </main>
    </div>
  );
};

export default PartnershipActiveAndInactiveCustomers;
