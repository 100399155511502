/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useActive } from '../../../../../hooks/sales/active-and-inactive/useActive';
import SalesActiveAndInactiveCustomersTable from './SalesActiveAndInactiveCustomersTable';
import useInstallationZones from '../../../shared/installations/zones/hooks/useInstallationZones';
import useUtils from '../../../shared/refund/hooks/useUtils';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, salesHeaders } from '../../../../../utils/field-support/installations/helpers';

const SalesActiveAndInactiveCustomersBody = () => {
  const { lists, rows, pages, isFetching, getActiveInactiveData, searchActiveInactiveData } = useActive();
  
  const { zones, loading } = useInstallationZones();
  const { states, loadings } = useUtils();
  const keywordState = {
    country: 'Nigeria',
    state: 'Lagos',
    zone: '',
  };
  const [keyword, setKeyword] = useState(keywordState);
  const [option, setOption] = useState('');
  const [page] = useState(1);

  const data = {
    country: keyword.country,
    state: keyword.state,
    zone: keyword.zone,
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    return searchActiveInactiveData(selectedPage, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword({ ...keyword, [name]: value });
  };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    getActiveInactiveData(data);
    searchActiveInactiveData(page, data);
  };

  useEffect(() => {
    getActiveInactiveData(data);
    searchActiveInactiveData(page, data);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              {/* <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="country">Country</option>
                  <option value="state">State</option>
                  <option value="zone">Zone</option>
                </select>
              </div> */}

              <div className="form-group ml-2">
              <label className="mt-2 p-0 copy-font ml-2 mr-2">Country</label>
                <select defaultValue={keyword.country} name="country" onChange={handleChange} className="form-control">
                  <option value="">--Choose Country--</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Ghana">Ghana</option>
                  <option value="cv">Côte d'Ivoire</option>
                </select>
              </div>

              <div className="form-group">
                <label className="mt-2 p-0 copy-font ml-2 mr-2">State</label>
                <select
                  className="form-control border border-info py-2"
                  id="state"
                  name="state"
                  value={keyword.state}
                  onChange={handleChange}
                >
                  <option value=""> Select an Option </option>
                  {!loadings ? (
                    states.map((zone) => (
                      <option key={zone.id} value={zone.state}>
                        {zone.state}
                      </option>
                    ))
                  ) : (
                    <option>Loading</option>
                  )}
                </select>
              </div>

              <div className="form-group">
                <label className="mt-2 p-0 copy-font ml-2 mr-2">Zone</label>
                <select
                  className="form-control border border-info py-2"
                  id="zone"
                  name="zone"
                  value={keyword.zone}
                  onChange={handleChange}
                >
                  <option value=""> Select an Option </option>
                  {!loading ? (
                    zones.map((zone) => (
                      <option key={zone.id} value={zone.name}>
                        {zone.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading</option>
                  )}
                </select>
              </div>

              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
              {option === 'zone' && <div className="form-group ml-2"></div>}
            </form>
          </div>
        </div>

        <div className="col-12 d-flex mt-2">
          <CustomCsvDownload
            data={lists || []}
            headers={salesHeaders}
            filename={filename(`Sales Customers`)}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download Data"
          />
        </div>

        <div className="card x-panel p-0">
          <SalesActiveAndInactiveCustomersTable lists={rows} isLoading={isFetching} />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesActiveAndInactiveCustomersBody;
