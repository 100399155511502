import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { IEmployees } from '../ConfirmedEmployees';
import { API_URL, API_URL_8443 } from '../../../../../utils/config';

const useEmployee = () => {
  const { profile } = useUser();
  const [employeeId, setEmployeeId] = useState({
    // eslint-disable-next-line @typescript-eslint/camelcase
    created_at: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
  });
  const [deactivateConfirmationModal, setDeactivateConfirmationModal] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [deletionConfirmationModal, setDeletionConfirmationModal] = useState(false);

  const openDeactivateConfirmation = () => {
    setDeactivateConfirmationModal(!deactivateConfirmationModal);
  };

  const openDeletionConfirmation = () => {
    setDeletionConfirmationModal(!deletionConfirmationModal);
  };

  const getEmployee = (employee) => {
    setEmployeeId(employee);
  };

  // console.log(employeeToEdit);

  const deactivateUser = async (e) => {
    e.preventDefault();
    setDeactivating(!deactivating);
    try {
      const response = await axios.put('https://tizeti-api.herokuapp.com/employee', {
        email: employeeId.email,
        adminEmail: profile.officialEmail,
      });
      if (response.data.status && response.data.message === 'User deactivated') {
        toast.success(response.data.message);
        setDeactivating(false);
        setDeactivateConfirmationModal(!deactivateConfirmationModal);
      } else {
        toast.success('Unable to deactivate the user');
      }
    } catch (error) {
      toast('Something went wrong!');
    }
  };

  const deleteEmployee = async (e, email, reload) => {
    e.preventDefault();
    setDeactivating(!deactivating);
    const config = {
      method: 'post',
      url: `${API_URL}/employee/deactivateEmployee`,
      data: {
        email: email,
      },

      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.status === 200) {
          // console.log('true');
          setDeactivating(false);
          setDeletionConfirmationModal(!deletionConfirmationModal);
          toast.success('Employee deleted successfully');
          reload();
        } else {
          toast.error('Unable to deleted employee');
        }
      })
      .catch((error) => {
        reload();
        setDeactivating(false);
        if (error) {
          toast.info('Something went wrong!');
        }
      });
  };

  // const deleteEmployees = async (e, id) => {
  //   e.preventDefault();
  //   setDeactivating(!deactivating);

  //   try {
  //     const response = await axios.post(`${API_URL_8443}/employee/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //     });
  //     if (response.status === 200) {
  //       setDeactivating(false);
  //       setDeletionConfirmationModal(!deletionConfirmationModal);
  //       toast.success('Employee deleted successfully');
  //       window.location.reload();
  //     } else {
  //       toast.error('Unable to deleted employee');
  //     }
  //   } catch (error) {
  //     setDeactivating(false);
  //     toast.error('Something went wrong!');
  //   }
  // };

  return {
    employeeId,
    deactivateConfirmationModal,
    deactivating,
    deletionConfirmationModal,
    openDeletionConfirmation,
    openDeactivateConfirmation,
    getEmployee,
    deactivateUser,
    deleteEmployee,
  };
};
export default useEmployee;
