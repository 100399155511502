/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { memo, useState, useEffect, ReactNode, SetStateAction } from 'react';
import { stringify } from 'qs';
import { toast } from 'react-toastify';
import view from '../../../../assets/img/icons/view.png';
import ViewCustomerDetails from '../../departments/asset-team/shared/ViewCustomerDetails';
import callbutton from '../../../../assets/img/icons/Call_Button.png';
// import editIcon from '../../../../assets/img/icons/edit.png';
import legacy from '../../../../services/api/axios';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import CustomButton from '../forms/CustomButton';
import AddComment from './AddComment';
import useMailBlast from '../mailblast/v2/hooks/useMailBlast';
import { useAssetTeamTable } from './hooks/useAssetTeamTable';
import CallPromptModal from './CallPromptModal';
import SendEmail from './SendEmail';
import SendSms from './send-sms/SendSms';
import SendBulkSms from './send-sms/SendBulkSms';
import MoveToBucket from '../MoveToBucket';
import { FormatTime } from '../../../../utils/asset-team/AssetTeamHelper';
import { OPERATIONS, PARTNERSHIP } from '../../../../utils/departments/helpers';
import { SALES } from '../../../../utils/config';

const AssetTeamTable = ({ profile, items, load }) => {
  const {
    address,
    isLoading,
    getUserAddress,
    commentModal,
    showCommentModal,
    addingComment,
    comment,
    addComment,
    onCommentChange,
  } = useAssetTeamTable();
  const departmentId = sessionStorage.getItem('departmentId');

  const { onEditorStateChange, editorState } = useMailBlast();
  const [show, setShow] = useState(false);
  const [smsData, setSmsData] = useState([]);
  const [newData, setNewData] = useState(items);
  const [callId, setCallId] = useState('');
  const [calling, setCalling] = useState(false);
  const [sendBulk, setSendBulk] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentData, setCommentData] = useState({ comment: '', time: '', details: '' });
  const [one, setOne] = useState({
    Name: '',
    name: '',
    mail: '',
    Mail: '',
    Mobile: '',
    address: '',
    username: '',
    Expiration_date: '',
    value: '',
    agent: '',
    Customer_Number: '',
    customer_ref: '',
    created_at: '',
    zone: '',
    basestation: '',
    country: '',
    region: '',
    subscription_status: '',
    id: 0,
    isChecked: false,
    comment: '',
    details: '',
    comment_addedby: '',
    comment_addedat: '',
    last_call_time: '',
    call_count: '',
  });

  const getItem = (params) => {
    setOne(params);
    getUserAddress(params);
  };

  console.log(items);

  useEffect(() => {
    if (items && items.length) {
      setNewData(
        items.map((item, i) => {
          const {
            username,
            Name,
            mail,
            Mail,
            Mobile,
            Expiration_date,
            basestation,
            agent,
            country,
            region,
            Customer_Number,
            customer_ref,
            created_at,
            zone,
            subscription_status,
            comment,
            details,
            comment_addedby,
            comment_addedat,
            call_count,
            last_call_time,
          } = item;
          return {
            isChecked: false,
            Name: Name || item.name,
            Mail: Mail || mail,
            Mobile: Mobile || item.address,
            Expiration_date: Expiration_date || item.value,
            basestation,
            agent,
            country,
            region,
            Customer_Number: Customer_Number || customer_ref,
            created_at,
            zone,
            username,
            subscription_status,
            comment,
            details,
            comment_addedby,
            comment_addedat,
            call_count,
            last_call_time,
            id: i + 1,
          };
        }),
      );
    }
  }, [items]);
  const callCustomer = () => {
    setCalling(true);

    legacy
      .post(
        '/index.php',
        stringify({
          action: 'retension_call_helper',
          staff_email: profile.officialEmail,
          customer_phone: one.Mobile,
          customer_cin: one.Customer_Number,
        }),
      )
      .then((response) => {
        setCalling(false);

        if (response.data.status === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setCallId(response.data.call_id);
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
        setCalling(false);
      });
  };
  const showModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  const handleAllChecked = (event) => {
    const { checked } = event.target;
    setNewData(
      newData.map((dat) => {
        dat.isChecked = checked;
        return dat;
      }),
    );
    if (newData.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };

  const getComment = (customer_no) => {
    setCommentLoading(true);
    legacy
      .post(
        '/index.php',
        stringify({
          action: 'view_retention_comment',
          customer_number: customer_no,
        }),
      )
      .then((res) => {
        setCommentData(res.data);
        setCommentLoading(false);
      })
      .catch((err) => {
        toast.error('Something went wrong');
        setCommentLoading(false);
      });
  };

  const showCommentModalTab = () => {
    return (
      <div id="comment_modal_tab" className="modal fade mt-5" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title my-2">Comment</h4>
              <button type="button" className="mr-2 close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body detail p-3">
              <div className="mb-2 item-font">
                Comment: {commentLoading ? 'Loading...' : commentData.comment ? commentData.comment : 'N/A'}
              </div>

              <div className="mb-2 item-font">
                Details: {commentLoading ? 'Loading...' : commentData.details ? commentData.details : 'N/A'}
              </div>

              <div className="item-font">
                Time Of Comment: {commentLoading ? 'Loading...' : commentData.comment ? commentData.time : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showComment = (customer_no) => {
    return (
      <>
        <button
          className="btn btn-gray"
          type="button"
          data-placement="right"
          data-toggle="modal"
          data-target="#comment_modal_tab"
          onClick={() => getComment(customer_no)}
        >
          comment
        </button>
        {showCommentModalTab()}
      </>
    );
  };

  const onSelectOne = (e, id) => {
    setNewData(
      newData.map((dat) => {
        if (id === dat.id) {
          dat.isChecked = e.target.checked;
        }
        return dat;
      }),
    );
    if (newData.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };
  const showMoveToBucket = (item) => {
    return (
      <div>
        <button
          type="button"
          className="noBorder"
          data-toggle="modal"
          data-placement="right"
          data-target="#moveToBucket"
          onClick={() => getItem(item)}
        >
          <span className="view">
            <i
              className="fa fa-arrow-circle-right"
              data-toggle="tooltip"
              data-placement="right"
              title="Move To Bucket"
            />
          </span>
        </button>
      </div>
    );
  };

  const tableData =
    items && items.length && newData && newData.length ? (
      newData.map((item, i) => {
        const {
          username,
          Name,
          Mail,
          Mobile,
          Expiration_date,
          Customer_Number,
          subscription_status,
          agent,
          zone,
          id,
          isChecked,
          comment,
          basestation,
          details,
          comment_addedby,
          comment_addedat,
          call_count,
          last_call_time,
        } = item;

        // const { fetchTime } = FormatTime(comment_addedat);
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>
              <input type="checkbox" checked={isChecked} value={id} onChange={(e) => onSelectOne(e, id)} />
            </td>
            <td>{username}</td>
            <td className="text-nowrap">{Name}</td>
            <td>{Mail}</td>
            <td>{Mobile}</td>
            <td>{Customer_Number}</td>
            <td>{basestation}</td>
            <td>
              <div
                className={`badge badge-${
                  subscription_status === 'active' ? 'success' : 'danger'
                } text-uppercase w-100`}
              >
                {subscription_status || 'N/A'}
              </div>
            </td>
            <td>{Expiration_date}</td>
            <td className="text-nowrap">{zone}</td>

            {departmentId != `${OPERATIONS}` && departmentId != `${PARTNERSHIP}` && departmentId != `${SALES}` ? (
              <>
                <td>{agent}</td>
                <td>
                  <div className="text-center">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-placement="right"
                      className="noBorder"
                      data-target="#sendMessage"
                      onClick={() => getItem(item)}
                    >
                      <span className="view">
                        <i className="fa fa-envelope-o" data-toggle="tooltip" data-placement="right" title="SMS" />
                      </span>
                    </button>
                  </div>
                  <SendSms profile={profile} oneItem={one} />
                </td>
                <td>
                  <div className="text-center">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-placement="right"
                      className="noBorder"
                      data-target="#sendEmail"
                      onClick={() => getItem(item)}
                    >
                      <span className="view">
                        <i className="fa fa-envelope" data-toggle="tooltip" data-placement="right" title="Email" />
                      </span>
                    </button>
                  </div>
                  <SendEmail
                    onEditorStateChange={onEditorStateChange}
                    editorState={editorState}
                    profile={profile}
                    oneItem={one.Mail}
                  />
                </td>
                <td
                  onClick={() => {
                    showModal();
                    getItem(item);
                  }}
                >
                  <div className="text-center">
                    <span className="view">
                      <img src={callbutton} alt="Call" title="Call" />
                    </span>
                  </div>
                </td>
                <td>{call_count}</td>
                <td>{last_call_time}</td>
                <td>{comment}</td>
                {/* <td
              onClick={() => {
                commentModal();
                getItem(item);
              }}
            >
              <div className="text-center">
                <span className="view">
                  <img src={editIcon} alt="edit" title="edit" />
                </span>
              </div>
            </td> */}
                <td>{showComment(Customer_Number)}</td>
                <td>{details || 'N/A'}</td>
                <td>{comment_addedby || 'N/A'}</td>
                <td>{comment_addedat || 'N/A'}</td>
                {/* <td className="text-nowrap">{fetchTime() || 'N/A'}</td> */}
                <td>
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="button"
                        className="noBorder"
                        data-toggle="modal"
                        data-placement="right"
                        data-target="#view_item"
                        onClick={() => getItem(item)}
                      >
                        <span className="view">
                          <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                        </span>
                      </button>
                    </div>
                    {showMoveToBucket(item)}
                  </div>
                  <ViewCustomerDetails oneItem={one} address={address} isLoading={isLoading} />
                  <MoveToBucket oneItem={one} address={address} profile={profile} operation="add" />
                </td>
              </>
            ) : null}

            <td>
              <div className="d-flex justify-content-between">
                <div>
                  <button
                    type="button"
                    className="noBorder"
                    data-toggle="modal"
                    data-placement="right"
                    data-target="#view_item"
                    onClick={() => getItem(item)}
                  >
                    <span className="view">
                      <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                    </span>
                  </button>
                </div>
                {departmentId != `${OPERATIONS}` && departmentId != `${PARTNERSHIP}` && departmentId != `${SALES}` && (
                  <>{showMoveToBucket(item)}</>
                )}
              </div>
              <ViewCustomerDetails oneItem={one} address={address} isLoading={isLoading} />

              <MoveToBucket oneItem={one} address={address} profile={profile} operation="add" />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );

  const openBulkModal = () => {
    setSmsData(
      newData
        .filter((da) => da.isChecked)
        .map((user) => ({
          email: user.Mail,

          phone: Number(user.Mobile),
        })),
    );
    setBulkModal(!bulkModal);
  };
  return (
    <div className="card x-panel mt-2 px-0">
      {sendBulk && (
        <div className="form-group mx-2">
          <CustomButton classname="btn btn-green" disabled={false} onClick={openBulkModal} name="Send Bulk SMS" />
        </div>
      )}
      <SendBulkSms data={smsData} show={bulkModal} onClose={openBulkModal} profile={profile} />

      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>
                <input type="checkbox" onChange={handleAllChecked} value="checkedall" />
              </th>
              <th>MAC ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Customer Number</th>
              <th>Basestation</th>
              <th>Status</th>
              <th>Expiration Date</th>
              <th>Zone</th>
              {departmentId != `${OPERATIONS}` && departmentId != `${PARTNERSHIP}` && departmentId != `${SALES}` ? (
                <>
                  <th>Agent</th>

                  <th>Send SMS</th>
                  <th>Send Email</th>
                  <th>Call</th>
                  <th>Call Count</th>
                  <th>Last Call Time</th>
                  <th>Comment</th>
                  <th>Show Comment</th>
                  <th>Details</th>
                  <th>Comment Added By</th>
                  <th>Comment Added At</th>
                </>
              ) : null}
              <th>Action</th>
            </tr>
          </thead>
          <CallPromptModal
            show={show}
            onClose={() => closeModal()}
            callUser={callCustomer}
            profile={profile}
            number={one.Customer_Number}
            callId={callId}
            calling={calling}
          />

          <AddComment
            profile={profile}
            show={showCommentModal}
            onClose={commentModal}
            loading={addingComment}
            comment={comment}
            onSubmit={addComment}
            onChange={onCommentChange}
            customer={one}
            addingComment={addingComment}
          />

          <tbody>{load ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(AssetTeamTable);
