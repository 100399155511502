/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import GeniatechLogsTable from './GeniatechLogsTable';
import { useGeniatechLogs } from '../../../../../hooks/coo-statistics/useGeniatechLogs';

const GeniatechLogsBody = () => {
  const { lists, rows, pages, isFetching, getGeniatechLogs, searchGeniatechLogs } = useGeniatechLogs();

  const keywordState = {
    macid: '',
    from: '',
    to: '',
    type: '',
  };
  const [keyword, setKeyword] = useState(keywordState);
  const [option, setOption] = useState('');
  const [page] = useState(1);

  const header = ['Email', 'Comment', 'MAC ID', 'Extending Days', 'Date', 'Name', 'Staff Email', 'Extending Type'];

  const data = {
    macid: keyword.macid,
    type: keyword.type,
    from: keyword.from,
    to: keyword.to,
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    return searchGeniatechLogs(selectedPage, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword({ ...keyword, [name]: value });
  };
  function handleDownloadExcel() {
    if (lists && window.XLSX) {
      const XLSX = window.XLSX;

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(lists);

      XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Geniatech Logs');

      XLSX.writeFile(workbook, 'GeniatechLogs.xlsx', { compression: true });
    }
  }
  const filterUsingKeyword = (e) => {
    e.preventDefault();
    getGeniatechLogs(data);
    searchGeniatechLogs(page, data);
  };

  useEffect(() => {
    getGeniatechLogs(data);
    searchGeniatechLogs(page, data);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group ml-2">
                <label className="mt-2 p-0 copy-font ml-2 mr-2"> Type</label>
                <select defaultValue={keyword.type} name="type" onChange={handleChange} className="form-control">
                  <option value="">--Choose Type--</option>
                  <option value="payment">Payment</option>
                  <option value="compensation">Compensation</option>
                  <option value="all">All</option>
                </select>
              </div>

              <div className="form-group ml-2">
                <label className="mt-2 p-0 copy-font ml-2 mr-2"> Mac ID</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Input Mac ID"
                  style={{ width: '230px' }}
                  name="macid"
                  value={keyword.macid}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group d-flex ml-2">
                <small className="mr-1">From: </small>
                <input type="date" className="form-control" name="from" value={keyword.from} onChange={handleChange} />
              </div>
              <div className="form-group d-flex ml-2">
                <small className="mr-1">To: </small>
                <input type="date" className="form-control" name="to" value={keyword.to} onChange={handleChange} />
              </div>
              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-12 d-flex mt-2">
          <button className="btn btn-green shadow-md ml-5 " onClick={handleDownloadExcel}>
            Download Excel
          </button>
        </div>

        <div className="card x-panel p-0">
          <GeniatechLogsTable lists={rows} isLoading={isFetching} />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeniatechLogsBody;
