/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AES, enc } from 'crypto-js';
import PATHS from '../../../routes/urls';
import AdminNavBarItem from './AdminNavBarItem';

const span13 = (tabName) => (
  <span className="text-uppercase" style={{ fontSize: '14px' }}>
    {tabName}
  </span>
);
const groupIcon = 'https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png';

const SuperAdminSideBar = () => {
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();
  if (sessionStorage.getItem('roleId') === '12') {
    return (
      <div className="s-layout__sidebar">
        <Link className="s-sidebar__trigger" to="#">
          <i className="fa fa-bars mr-3" />
        </Link>

        <nav className="s-sidebar__nav">
          <div className="">
            <Link className="navbar-brand-logo" to="/super-admin">
              <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
            </Link>
            <ul className="">
              <div className="">
                <AdminNavBarItem url="/super-admin" name={span13('Overview')} src={groupIcon} target="_self" />
                <li className="s-sidebar__nav-link">
                  <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                        alt="img"
                      />
                    </span>
                    <small>New system (Beta v2.0)</small>
                  </a>
                </li>

                <AdminNavBarItem
                  url={PATHS.CUSTOMER_SUPPORT_OVERVIEW}
                  name={span13('CUSTOMER SUPPORT')}
                  src={groupIcon}
                  target="_blank"
                />

                <AdminNavBarItem url={PATHS.RD_OVERVIEW} name={span13('R & D')} src={groupIcon} target="_blank" />

                <AdminNavBarItem
                  url={PATHS.NEW_PRODUCTS_OVERVIEW}
                  name={span13('WIFICALL')}
                  src={groupIcon}
                  target="_new"
                />
              </div>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <div className="">
          <Link className="navbar-brand-logo" to="/super-admin">
            <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
          </Link>

          <ul className="">
            <div className="">
              <AdminNavBarItem url="/super-admin" name={span13('Overview')} src={groupIcon} target="_self" />
              <li className="s-sidebar__nav-link">
                <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                  </span>
                  <small>New system (Beta v2.0)</small>
                </a>
              </li>
              <AdminNavBarItem url="/super-admin/geniatech-logs" name={span13('Geniatech Logs')} src={groupIcon} target="_self" />
              <AdminNavBarItem url={PATHS.ACCOUNTS_OVERVIEW} name={span13('ACCOUNTS')} src={groupIcon} target="_self" />
              <AdminNavBarItem
                url={PATHS.ASSET_TEAM_CLOSED_LEADS}
                name={span13('ASSET TEAM')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem
                url={PATHS.CUSTOMER_SUPPORT_OVERVIEW}
                name={span13('CUSTOMER SUPPORT')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem
                url={PATHS.FIELD_SUPPORT_OVERVIEW}
                name={span13('FIELD SUPPORT')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem url={PATHS.NOC_OVERVIEW} name={span13('NOC')} src={groupIcon} target="_self" />
              <AdminNavBarItem
                url={PATHS.PROCUREMENT_OVERVIEW}
                name={span13('PROCUREMENT')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem url={PATHS.SALES_OVERVIEW} name={span13('SALES')} src={groupIcon} target="_self" />

              {/* <AdminNavBarItem
              url={PATHS.WIFICALL_OVERVIEW}
              name={span13('WIFICALL')}
              src={groupIcon}
              target="_new"
            /> */}
            </div>

            <div className="">
              <AdminNavBarItem
                url={PATHS.SUPER_ADMIN_RETENTION}
                name={span13('Retention')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem
                url={PATHS.SUPER_ADMIN_ALL_APPROVED_REQUISITIONS}
                name={span13('Approved AccountsHrRequisitions')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem
                url={PATHS.SUPER_ADMIN_REFUNDS}
                name={span13('Refunds')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem
                url={PATHS.SUPER_ADMIN_REQUISITIONS}
                name={span13('Requisitions')}
                src={groupIcon}
                target="_self"
              />
              <AdminNavBarItem url="/coo/kpi-dashboard" name={span13('Statistics')} src={groupIcon} target="_self" />
              <li className="s-sidebar__nav-link d-none d-md-block">
                <a href={PATHS.SUPER_ADMIN_RECRUITMENT} target="_blank" rel="noopener noreferrer" className="">
                  <span className="mr-2">
                    <img src={groupIcon} alt="img" />
                  </span>
                  {span13('Recruitment')}
                </a>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default SuperAdminSideBar;
