/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const SalesActiveAndInactiveCustomersTable = ({ lists, isLoading }) => {
  const colSpan = '8';

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.UserName}`}</td>
            <td className="text-nowrap">{`${list.Name}`}</td>
            <td>{list.Mail}</td>
            <td>{list.Mobile}</td>
            <td className="text-nowrap">{list.address}</td>

            <td>{list.called_station || 'N/A'}</td>

            <td>{list.zone || 'N/A'}</td>
            <td>{list.expiration_date}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>MAC ID</th>
            <th>Name</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap"> Phone Number</th>
            <th className="text-nowrap"> Address</th>
            <th className="text-nowrap">Basestation</th>
            <th className="text-nowrap">Zone</th>
            <th className="text-nowrap">Expiration Date </th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default SalesActiveAndInactiveCustomersTable;
