/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const GeniatechLogsTable = ({ lists, isLoading }) => {
  const colSpan = '8';

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{list.macid || 'N/A'}</td>
            <td>{list.name || 'N/A'}</td>
            <td className="text-nowrap">{`${list.email || 'N/A'}`}</td>
            <td className="text-nowrap">{`${list.extending_days || 'N/A'}`}</td>
            <td>{list.extension_type || 'N/A'}</td>
            <td>{list.comment || 'N/A'}</td>
            <td className="text-nowrap">{list.staff_email || 'N/A'}</td>

            <td>{list.created_at.split('T')[0] || 'N/A'}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>MAC ID</th>
            <th>Name</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap"> Extending Days </th>
            <th className="text-nowrap"> Extending Type</th>
            <th className="text-nowrap">Comment</th>
            <th className="text-nowrap">Staff Email</th>
            <th className="text-nowrap">Date </th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default GeniatechLogsTable;
