import React from 'react';
import { useEffect } from 'react';
import SuperAdminNavBar from '../../../../layouts/super-admin/SuperAdminNavBar';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import GeniatechLogsBody from './GeniatechLogsBody';

const GeniatechLogs = () => {
  const { profile, getUserProfile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="">
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <SuperAdminNavBar currentPage="Geniatech Logs" position={profile.firstName} />
          <GeniatechLogsBody />
        </main>
      </div>
    </div>
  );
};

export default GeniatechLogs;
