import { useEffect, useState } from 'react';
import axios from 'axios';

const useUtils = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [states, setStates] = useState([]);
  const [banks, setBanks] = useState([]);

  const getStates = async () => {
    setLoading(true);
    setLoadings(true);
    try {
      const result = await axios.get('https://api.tizeti.com/api/v1/legacy/get_list_of_regions', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (result.data) {
        setStates(result.data);
      }

      setLoading(false);
      setLoadings(false);
    } catch (error) {
      setLoading(false);
      setLoadings(false);
      console.log(error);
    }
  };

  const getBanks = async () => {
    setLoading(true);
    try {
      const result = await axios.get('https://nigerianbanks.xyz/');

      if (result.status === 200) {
        setBanks(result.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getStates();
    getBanks();
  }, []);

  return {
    loading,
    states,
    loadings,
    banks,
  };
};

export default useUtils;
