import React, { useState, useEffect } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/employee.css';
import AddEmployee from '../../departments/super-admin/employees/AddEmployee';
import { API_URL } from '../../../../utils/config';
import ConfirmedEmployees from '../staff/ConfirmedEmployees';
import UnconfirmedEmployees from '../staff/UnconfirmedEmployees';
import CustomCsvDownload from '../CustomCsvDownload';
import { employeeHeaders, filename } from '../../../../utils/field-support/installations/helpers';

const AdminEmployeeBody = (props) => {
  const [query, setQuery] = useState('');
  const [confirmed, setConfirmed] = useState('');
  const [unconfirmed, setUnconfirmed] = useState('');
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEmployees = async (status) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/employeeReg?confirmed=${status}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setIsLoading(false);
      if (status === true) {
        setConfirmed(data);
        setUnconfirmed([]);
      } else {
        setConfirmed([]);
        setUnconfirmed(data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees(status);
  }, []);

  const handleFilterChange = (e) => {
    // Convert the string to a boolean
    // console.log(e.target.value);
    setStatus(e.target.value === 'true' ? true : false);
    fetchEmployees(e.target.value === 'true' ? true : false);
  };

  const role_id = sessionStorage.getItem('roleId');

  return (
    <div className="pt-5 mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 search d-flex">
            <div className="d-flex employ inner-addon left-addon">
              <input
                className="form-control w-75 mt-1 mr-auto"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                id="iconified"
                type="text"
                placeholder="Search"
                style={{ marginLeft: '1rem' }}
              />
              <span className="fa fa-search" />
              &nbsp; &nbsp; &nbsp;
              {role_id > 2 && role_id != 6 && (
                <p className="op copy-font d-flex">
                  <small className="mt-2 "> Filter By: </small>
                  <span>
                    <select
                      className="form-control"
                      // defaultChecked={state.value}
                      value={status}
                      onChange={(e) => handleFilterChange(e)}
                    >
                      <option value="true">Confirmed Employees</option>
                      <option value="false">Unconfirmed Employees</option>
                    </select>
                  </span>
                </p>
              )}
            </div>
          </div>
          <AddEmployee />
          {confirmed.length > 0 ? (
            <div className="col-12 d-flex m-2">
              <CustomCsvDownload
                data={confirmed || []}
                headers={employeeHeaders}
                filename={filename(`Employee`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          ) : (
            <div className="col-12 d-flex m-2">
              <CustomCsvDownload
                data={unconfirmed || []}
                headers={employeeHeaders}
                filename={filename(`Employee`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          )}

          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table col-lg-12 col-md-12 col-sm-12">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Date of resumption</th>

                    <th>Action</th>
                  </tr>
                </thead>

                {status === true ? (
                  <ConfirmedEmployees
                    confirmed={confirmed}
                    isLoading={isLoading}
                    fetchEmployees={fetchEmployees}
                    query={query}
                    status={status}
                  />
                ) : (
                  <UnconfirmedEmployees
                    unconfirmed={unconfirmed}
                    isLoading={isLoading}
                    fetchEmployees={fetchEmployees}
                    query={query}
                    status={status}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEmployeeBody;
