/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';

export const useGeniatechLogs = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pages, setPages] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const getGeniatechLogs = async (data) => {
    // dispatch({ type: LOADING_STARTS });
    setIsLoading(true);

    const config = {
      method: 'post',
      url: `https://api.tizeti.com/api/v1/geniatechlog/search`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setLists(response.data.data);
          setIsLoading(false);
          //   dispatch({
          //     type: CLEAN_LTE,
          //     payload: {
          //       lists: response.data.data,
          //     },
          //   });
        }
      })
      .catch((e) => {
        console.log(e.response.data.message);
        toastr.info(e.response.data.message || 'Something went wrong!');
        // dispatch({ type: LOADING_STOPS });
        setIsLoading(false);
      });
  };

  const searchGeniatechLogs = async (page, data) => {
    // dispatch({ type: LOADING_STARTS });
    setIsFetching(true);

    const config = {
      method: 'post',
      url: `https://api.tizeti.com/api/v1/geniatechlog/search?page=${page}`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setRows(response.data.data);
          setPages(response.data.total_pages);
          setIsFetching(false);
        }
      })
      .catch((e) => {
        // console.log(e.response.data)
        toastr.info(e.response.data.message || 'Something went wrong!');
        // dispatch({ type: LOADING_STOPS });
        setIsFetching(false);
      });
  };

  return {
    lists,
    isFetching,
    rows,
    pages,
    isLoading,
    searchGeniatechLogs,
    getGeniatechLogs,
  };
};
