/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import SalesActiveAndInactiveCustomersBody from './SalesActiveAndInactiveCustomersBody';

const SalesActiveAndInactiveCustomers = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Active And Inactive Customers" roleID={user.role_id} position={firstName} />
        <SalesActiveAndInactiveCustomersBody profile={profile} />
      </main>
    </div>
  );
};

export default SalesActiveAndInactiveCustomers;
